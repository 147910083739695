import React from "react";

// layouts
import Layout1 from "./layouts/layout1/layout1";
import { CheckeIcon } from "./Icons";
import Login from "./pages/login/login";

// components
const PlansList = React.lazy(() => import("./components/plans/plans-list"));
const Threads = React.lazy(() => import("./components/threads/threads"));

// const Payment = React.lazy(() => import("./components/stripe-payment/stripe"));
const Dashboard = React.lazy(() => import("./components/dashboard/dashboard"));
const Nft = React.lazy(() => import("./components/threads/nft"));
const Success = React.lazy(() => import("./components/threads/success"));
const Thread = React.lazy(() => import("./components/threads/thread"));
const BuyMoreTokens = React.lazy(() => import("./components/more-tokens/buyMoreTokens"));
const CancelSubscription = React.lazy(() => import("./components/cancel-subscription/cancelSubscritption"));
const Home = React.lazy(() => import("./pages/home/Home"));
const Pricing = React.lazy(() => import("./pages/pricing/Pricing"));
const BillingDashboard = React.lazy(() => import("./pages/billing/BillingDashboard"));
const CancelPricing = React.lazy(() => import("./pages/pricing/CancelPricing"));
const BuyTokens = React.lazy(() => import("./pages/billing/BuyTokens"));
const UpdateStatus = React.lazy(() => import("./components/UpdateStatus"));
const NotFound = React.lazy(() => import("./pages/not-found/not-found"));
const Payment = React.lazy(() => import("./pages/stripe-payment/stripe"));

// routes
const routes = [
  {
    path: "/",
    access: true,
    exact: true,
    title: "Home",
    layout: Layout1,
    component: Home,
  },
  {
    path: "/login",
    access: true,
    exact: true,
    title: "Home",
    layout: Layout1,
    component: Login,
  },
  {
    path: "/thread/:id",
    access: true,
    exact: true,
    title: "Home",
    layout: Layout1,
    component: Home,
  },
  {
    // for upgrade plan add /upgrade-plan ,upgrade_plan and for /manage-plan, manage_pricing
    path: "/upgrade-plan",
    access: true,
    exact: true,
    title: "Pricing",
    layout: Layout1,
    component: Pricing,
  },
  {
    path: "/manage-plan",
    access: true,
    exact: true,
    title: "Pricing",
    layout: Layout1,
    component: Pricing,
  },
  {
    path: "/pricing",
    access: true,
    exact: true,
    title: "Pricing",
    layout: Layout1,
    component: Pricing,
  },
  {
    path: "/pricing/success-status",
    access: true,
    exact: true,
    title: "success-status",
    layout: Layout1,
    component: UpdateStatus,
  },
  {
    path: "/pricing/cancel-status",
    access: true,
    exact: true,
    title: "cancel-status",
    layout: Layout1,
    component: UpdateStatus,
  },
  {
    path: "/pricing/upgrade-status",
    access: true,
    exact: true,
    title: "upgrade-status",
    layout: Layout1,
    component: UpdateStatus,
  },
  {
    path: "/billing-dashboard",
    access: true,
    exact: true,
    title: "billing-dashboard",
    layout: Layout1,
    component: BillingDashboard,
  },
  {
    path: "/cancel-account",
    access: true,
    exact: true,
    title: "cancel-account",
    layout: Layout1,
    component: CancelPricing,
  },
  {
    path: "/buy-tokens",
    access: true,
    exact: true,
    title: "buy-tokens",
    layout: Layout1,
    component: BuyTokens,
  },
  {
    path: "/pricing",
    access: true,
    exact: true,
    title: "success-status",
    layout: Layout1,
    component: UpdateStatus,
  },
  {
    path: "/payment",
    access: true,
    exact: true,
    name: "payment",
    layout: Layout1,
    component: Payment,
  },
  // {
  //   path: "/plans",
  //   access: true,
  //   exact: true,
  //   title: "Plans List",
  //   layout: Layout1,
  //   component: PlansList,
  // },

  // {
  //   path: "/",
  //   access: true,
  //   exact: true,
  //   title: "Threads List",
  //   layout: Layout1,
  //   component: Threads,
  // },
  // {
  //   path: "/thread/:id",
  //   access: true,
  //   exact: true,
  //   title: "Create a new thread",
  //   layout: Layout1,
  //   component: Thread,
  // },
  // {
  //   path: "/nft/:id",
  //   access: true,
  //   exact: true,
  //   title: "Create a nft",
  //   layout: Layout1,
  //   component: Nft,
  // },
  // {
  //   path: "/success",
  //   access: true,
  //   exact: true,
  //   title: "Create a nft",
  //   layout: Layout1,
  //   component: Success,
  // },
  // {
  //   path: "/billing",
  //   access: true,
  //   exact: true,
  //   name: "billing",
  //   layout: Layout1,
  //   component: Dashboard,
  // },
  // {
  //   path: "/buy-more-tokens",
  //   access: true,
  //   exact: true,
  //   name: "BuyMoreTokens",
  //   layout: Layout1,
  //   component: BuyMoreTokens,
  // },
  // {
  //   path: "/cancel-subscription",
  //   access: true,
  //   exact: true,
  //   name: "CancelSubscription",
  //   layout: Layout1,
  //   component: CancelSubscription,
  // },
  {
    path: "/*",
    access: true,
    exact: true,
    name: "Not Found",
    layout: Layout1,
    component: NotFound,
  },
];

export default routes;

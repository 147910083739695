// ERRORS
export const EMPTY_ERRORS = "EMPTY_ERRORS";
export const GET_ERRORS = "GET_ERRORS";

// USERS
export const BEFORE_USER = "BEFORE_USER";
export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";

// THREADS
export const GET_THREADS = "GET_THREADS";
export const BEFORE_THREAD = "BEFORE_THREAD";
export const GET_OLD_THREADS = "GET_OLD_THREADS";
export const CREATE_THREAD = "CREATE_THREAD";
export const UPDATE_THREAD = "UPDATE_THREAD";
export const CREATE_NFT = "CREATE_NFT";
export const SELECTED_NFT = "SELECTED_NFT";
export const CHOOSEN = "CHOOSEN";




//PACKAGES
export const BEFORE_PACKAGES = "BEFORE_PACKAGES";
export const GET_PACKAGES = "GET_PACKAGES";

//PAYMENT
export const BEFORE_PAYMENT = "BEFORE_PAYMENT";
export const GET_PAYMENT = "GET_PAYMENT";
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const LIST_PAYMENT = "LIST_PAYMENT";
export const INVOICE_PRINT = "INVOICE_PRINT";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";

//SETTING
export const GET_SETTING = "GET_SETTING";
export const BEFORE_SETTING = "BEFORE_SETTING";

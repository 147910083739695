import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import FullPageLoader from "../FullPageLoader/FullPageLoader";




const Login = () => {
    // const { id } = useParams();
    const queryParameters = new URLSearchParams(window.location.search)
    const encuse = queryParameters.get("encuse");
    const [isLogin, setLogin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (encuse) {
            localStorage.setItem("encuse", atob(encuse));
            setLogin(true);
        }
    }, [encuse])

    useEffect(() => {
        if (isLogin) {
            navigate('/')
        }
    }, [isLogin])
    return (
        <FullPageLoader />
    );
};

export default Login;

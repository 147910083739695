import { BEFORE_SETTING, GET_SETTING } from "../../redux/types";
const initialState = {
  setting: null,
  getSettingAuth: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SETTING:
      return {
        ...state,
        setting: action.payload,
        getSettingAuth: true,
      };
    case BEFORE_SETTING:
      return {
        ...state,
        setting: null,
        getSettingAuth: false,
      };
    default:
      return {
        ...state,
      };
  }
}

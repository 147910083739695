import { GET_PACKAGES, BEFORE_PACKAGES } from "../../redux/types";
const initialState = {
  package: null,
  packages: null,
  getPackagesAuth: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PACKAGES:
      return {
        ...state,
        packages: action.payload,
        getPackagesAuth: true,
      };
    case BEFORE_PACKAGES:
      return {
        ...state,
        package: null,
        packages: null,
        getPackagesAuth: false,
      };
    default:
      return {
        ...state,
      };
  }
}

import { combineReducers } from "redux";
// import threadsReducer from "../components/threads/threads.reducer";
import userReducer from "../pages/user/user.reducer";
import packagesReducer from "../pages/pricing/plans.reducer";
import paymentReducer from "../pages/stripe-payment/stripe-payment.reducer";
import settingsReducer from "../pages/settings/settings.reducer";
import homeReducer from "../pages/home/home.reducer";
import errorReducer from "../redux/shared/error/error.reducer"
export default combineReducers({
  user: userReducer,
  // thread: threadsReducer,
  packages: packagesReducer,
  payments: paymentReducer,
  settings: settingsReducer,
  home: homeReducer,
  error: errorReducer
});

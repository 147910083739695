import {
    GET_THREADS, BEFORE_THREAD, GET_OLD_THREADS, CREATE_THREAD, CREATE_NFT, SELECTED_NFT, UPDATE_THREAD, CHOOSEN
} from '../../redux/types';

const initialState = {
    getThreadRes: null,
    getThreadAuth: false,

    getOldThreadRes: null,
    getOldThreadAuth: false,

    createThreadRes: null,
    createThreadAuth: false,

    editThreadRes: null,
    editThreadAuth: false,

    choosenRes: null,
    choosenAuth: false,

    createNftRes: null,
    createNftAuth: false,

    nftRes: null,
    nftAuth: false,


}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_THREADS:
            return {
                ...state,
                getThreadRes: action.payload,
                getThreadAuth: true,
            }
        case GET_OLD_THREADS:
            return {
                ...state,
                getOldThreadRes: action.payload,
                getOldThreadAuth: true,
            }
        case CREATE_THREAD:
            return {
                ...state,
                createThreadRes: action.payload,
                createThreadAuth: true,
            }
        case UPDATE_THREAD:
            return {
                ...state,
                editThreadRes: action.payload,
                editThreadAuth: true,
            }
        case CREATE_NFT:
            return {
                ...state,
                createNftRes: action.payload,
                createNftAuth: true,
            }
        case SELECTED_NFT:
            return {
                ...state,
                nftRes: action.payload,
                nftAuth: true,
            }
        case CHOOSEN:
            return {
                ...state,
                choosenRes: action.payload,
                choosenAuth: true,
            }
        case BEFORE_THREAD:
            return {
                ...state,
                getThreadRes: null,
                getThreadAuth: false,
                getOldThreadRes: null,
                getOldThreadAuth: false,
                createThreadRes: null,
                createThreadAuth: false,
                createNftRes: null,
                createNftAuth: false,
                nftRes: null,
                nftAuth: false,
                editThreadRes: null,
                editThreadAuth: false,
                choosenRes: null,
                choosenAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}
import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './components/shared/scroll-to-top/scroll-to-top'
import { Provider } from 'react-redux';
import store from './store';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

// COMMENTED TEMP.
// toast.configure({
//   position: toast.POSITION.TOP_RIGHT,
//   pauseOnHover: true,
//   newestOnTop: true,
//   autoClose: 3000
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer />
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();

import { BEFORE_USER, SET_USER, GET_USER } from '../../redux/types';

const initialState = {
    userData: null,
    userAuth: false,
}

export default function userRed(state = initialState, action) {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                userData: action.payload,
                userAuth: true
            }
        case GET_USER:
            return {
                ...state
            }
        case BEFORE_USER:
            return {
                ...state,
                userData: null,
                userAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}